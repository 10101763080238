<!-- Settings -->
<template>
    <div class="overflow-auto">
        <div class="m-bot-10 text-right">
            <a @click="createWebsite"><b-button variant="primary" class="btn rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase btn-primary"><i class="fa fa-plus"></i>Add New store</b-button></a>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading"  v-loading="loading">
              <el-table-column fixed align="center" label='Logo' prop="id" sortable width="150" >
                <template slot-scope="scope">
                <span class="website-image" v-loadimage="scope.row.website_logo"></span>
                </template>
              </el-table-column>
              <el-table-column label="Blog ID" sortable width="120" align="center">
                <template slot-scope="scope">
                {{(scope.row.website_id)}}
                </template>
              </el-table-column>
              <el-table-column label="Store Name" sortable width="180" align="center">
                <template slot-scope="scope">
                {{(scope.row.website_name)}}
                </template>
              </el-table-column>
              <el-table-column label="Store Prefix" sortable width="150" align="center" >
                <template slot-scope="scope">
                {{(scope.row.website_prefix)}}
                </template>
              </el-table-column>
              <el-table-column label="Store Url" sortable>
                <template slot-scope="scope">
                {{(scope.row.website_url)}}
                </template>
              </el-table-column>
               <el-table-column label="Status" align="center" sortable width="100" >
                <template slot-scope="scope" >
                 <span class="active text-center" v-if="scope.row.is_active ===1">Active</span>
                 <span class="inact text-center" v-else>In Active</span>
               </template>
              </el-table-column>
               <el-table-column  align="center" label="Actions" width="80">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">       
                      <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>
                      <el-dropdown-item :command="2" ><i class="fa fa-pen"></i>Edit</el-dropdown-item>
                      <el-dropdown-item :command="3" ><i class="fa fa-trash"></i>Delete</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>        
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllSites, deleteWebsite} from "@/api/website";
  export default {
    name: "list-website",
    data() {
      return { 
        list: [],
         loading: false,
         sort: null,
         pagination: {},
         q: null,
         image:'',
      }
    },
    computed: {
     
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },       
        { title: "Stores" }
      ]);
    },
    created(){
      this.fetchData();
    },
    methods:{
      fetchData(p) {
      this.loading = true
      let params = { page: p }
      if(this.q){
        params.q = this.q
      }
      if(this.sort){
        params.sort = this.sort;
      }
      getAllSites(params).then(response => {
        this.list = response.data.data
        //this.pagination = response.data.data.pagination
        this.loading = false
      })
    },
    createWebsite()
    {
      this.$router.push({path:'/website/create/'})
    },
    handleActions(evt, row)
    {
        if (evt === 1){
          this.$router.push({path:'/website/view/'+row.id})
        }
        else if (evt === 2){
          this.$router.push({path:'/website/edit/'+row.id})
        }
        else if (evt === 3){
          this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
            if(value){
              this.loading = true;
              deleteWebsite(row.id).then(response => {
                if(response.data.status){
                    this.fetchData(1)
                    this.$showResponse('success', response.data.message);
                }
                this.loading = false;
              })
            }else{
              return false;
            }
          })
        }
    },
    }
  }
</script>